import { IonContent } from '@ionic/react';
import React from 'react';
import '../Page.css';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import Step5 from './Steps/Step5';
import Step6 from './Steps/Step6';
import Step7 from './Steps/Step7';
// import { PermitStore } from '../../store/PermitStore';

const ApplicationSteps = () => {
    // const application = PermitStore.useState((s) => s.application);

    // useEffect(() => {
    //     console.log('application data => ', application);
    // }, [application]);
    return (
        <IonContent>
            {/****** First Name Area ******/}
            <Step1 />
            {/****** Last Name Area ******/}
            <Step2 />
            {/****** Phone Number Area ******/}
            <Step3 />
            {/****** Home Address Area ******/}
            <Step4 />
            {/****** Confirmation Area ******/}
            <Step5 />
            {/****** Payment ******/}
            <Step6 />
            {/****** Success ******/}
            <Step7 />
        </IonContent>
    );
};

export default ApplicationSteps;
