import React from 'react';
import { IonGrid, IonRow, IonCol, IonImg, IonSpinner, IonButton } from '@ionic/react';
import { TownStore } from '../store/TownStore';

const Footer = () => {
    const townDoc = TownStore.useState((s) => s.townDoc);
    // console.log(townDoc)

    if (townDoc) {
        return (
            <div className='foot' color='medium'>
                <IonGrid className='ion-padding'>
                    <IonRow className='ion-padding'>
                        <IonCol className='ion-padding' sizeXs='12' sizeMd='4'>
                            <h3>{townDoc.blurbTitle}</h3>
                            <p>{townDoc.blurb}</p>
                            <IonButton
                                color={'danger'}
                                target='_blank'
                                shape='round'
                                href={townDoc.byLaw}
                            >
                                Burning By-law
                            </IonButton>
                        </IonCol>
                        <IonCol className='ion-padding' sizeXs='12' sizeMd='4'>
                            <h3>Headquarters</h3>
                            {townDoc.image ? <IonImg src={townDoc.image} /> : null}
                            <p>{townDoc.address}</p>
                        </IonCol>
                        <IonCol className='ion-padding' sizeXs='12' sizeMd='4'>
                            <h3>Contact Us</h3>
                            <p>{townDoc.phone}</p>
                            {townDoc.fax ? <p>Fax: {townDoc.fax}</p> : null}
                            {townDoc.email ? (
                                <p>
                                    Email: <a href={`mailto:${townDoc.email}`}>{townDoc.email}</a>{' '}
                                </p>
                            ) : null}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
        );
    } else {
        return (
            <div className='foot' color='medium'>
                <IonGrid className='ion-padding'>
                    <IonRow className='ion-padding'>
                        <IonCol className='ion-padding' sizeXs='12' sizeMd='4'>
                            {/* <IonSpinner /> */}
                        </IonCol>
                        <IonCol className='ion-padding' sizeXs='12' sizeMd='4'>
                            <p className='ion-text-center'>
                                <IonSpinner />
                            </p>
                        </IonCol>
                        <IonCol className='ion-padding' sizeXs='12' sizeMd='4'>
                            {/* <IonSpinner /> */}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
        );
    }
};

export default Footer;
