import React, { useEffect, useState } from 'react';
import '../../Page.css';
import {
    IonButton,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonRadio,
    IonRadioGroup,
} from '@ionic/react';
/* Pullstate Store */
import { PermitStore } from '../../../store/PermitStore';
import firebaseApp, { firestore } from '../../../firebase';
import { UserStore } from '../../../store/UserStore';
import dayjs from 'dayjs';
import AddressPicker from '../../AddressPicker';
import ManualAddress from '../../ManualAddress';

const Step5 = () => {
    const step = PermitStore.useState((s) => s.applicationStep);
    const application = PermitStore.useState((s) => s.application);
    const userDoc = UserStore.useState((s) => s.userDoc);
    const [showBusinessInput, setShowBusinessInput] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);

    const createPermit = () => {
        setShowLoading(true);
        firestore
            .collection('permits')
            .doc(userDoc.lastName + '-' + application.type + '-' + application.startDate)
            .set({
                ...application,
            })
            .then(() => {
                firestore
                    .collection('users')
                    .doc(firebaseApp.auth().currentUser.uid)
                    .collection('permits')
                    .doc(userDoc.lastName + '-' + application.type + '-' + application.startDate)
                    .set({
                        ...application,
                    });
            })
            .then(() => {
                setShowLoading(false);
                PermitStore.update((s) => {
                    s.applicationStep = 7;
                });
            })
            .catch((error) => {
                console.log(error);
                setShowLoading(false);
                alert(
                    'There was an error processing your application. Please try again. If this error perists, please contact support.'
                );
            });
    };
    useEffect(() => {
        if (!showBusinessInput) {
            PermitStore.update((s) => {
                s.application = {
                    ...application,
                    businessName: null,
                };
            });
        }
        // console.log(application);
    }, [application, showBusinessInput]);
    return (
        <div className={step === 5 ? 'steps' : 'ion-hide'}>
            <IonLoading
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Please wait...'}
            />
            <h2 className='ion-padding-horizontal onboardingTitles'>
                Please confirm application details
            </h2>
            <div style={{ height: '0px' }} />
            <div className='ion-padding'>
                <h2>Property Owner: </h2>
                <IonRadioGroup
                    mode='md'
                    aria-label={'List of burn permits'}
                    value={showBusinessInput}
                    onIonChange={(e) => {
                        setShowBusinessInput(e.target.value);
                    }}
                >
                    <IonItem aria-label={'address'}>
                        <IonLabel className='ion-text-wrap'>
                            {userDoc.firstName + ' ' + userDoc.lastName}
                        </IonLabel>
                        <IonRadio slot='start' value={false} />
                    </IonItem>
                    <IonItem aria-label={'address'}>
                        <IonLabel className='ion-text-wrap'>Other</IonLabel>
                        <IonRadio slot='start' value={true} />
                    </IonItem>
                </IonRadioGroup>
                {showBusinessInput ? (
                    <div className='ion-padding'>
                        <h3>Please provide the property owner's information below:</h3>
                        <IonItem>
                            <IonLabel position='floating'>Property Owner Full Name</IonLabel>
                            <IonInput
                                placeholder={`Please enter the property owner's name`}
                                onIonChange={(e) =>
                                    PermitStore.update((s) => {
                                        s.application = {
                                            ...application,
                                            propertyOwner: e.detail.value,
                                        };
                                    })
                                }
                            />
                        </IonItem>
                        <div className='ion-padding-vertical'>
                            {!notFound ? (
                                <AddressPicker context='propertyOwner' />
                            ) : (
                                <ManualAddress context='propertyOwner' />
                            )}
                        </div>
                        <p className='ion-text-center ion-no-margin'>
                            <small>
                                {!notFound
                                    ? `Don't see the address listed?`
                                    : `Use Google to find my address`}
                            </small>
                        </p>
                        <p className='ion-text-center ion-no-margin ion-margin-bottom'>
                            <IonButton
                                onClick={() => setNotFound(!notFound)}
                                size='small'
                                fill='clear'
                            >
                                Click here
                            </IonButton>
                        </p>
                    </div>
                ) : null}
                <h2 className='onboardingTitles'>Type:</h2>
                <p className=''>{application.type}</p>
                {application.businessName ? (
                    <>
                        <h2 className='onboardingTitles'>Business Name:</h2>
                        <p>{application.businessName}</p>
                    </>
                ) : null}
                <h2 className='onboardingTitles'>Valid:</h2>
                <p className=''>
                    {dayjs(Date.now()).format('MMMM DD, YYYY')} to{' '}
                    {application.validFor === 'EOY'
                        ? dayjs(application.endDate).format('MMMM DD, YYYY')
                        : dayjs(Date.now() + application.validFor).format('MMMM DD, YYYY')}
                </p>

                <h2 className='onboardingTitles'>Address:</h2>
                <p className=''>
                    {application.address !== 'other'
                        ? application.address
                        : application.otherAddress}
                </p>
                <h2 className='onboardingTitles'>Price:</h2>
                <p className=''>${application.price}</p>
            </div>
            <IonButton
                disabled={
                    (showBusinessInput && !application.propertyOwner) ||
                    (showBusinessInput && !application.propertyOwnerAddress)
                        ? true
                        : false
                }
                className='ion-margin'
                shape='round'
                expand='block'
                onClick={() => {
                    application.price > 0
                        ? PermitStore.update((s) => {
                              s.applicationStep = 6;
                          })
                        : createPermit();
                }}
            >
                {application.price > 0 ? 'Proceed to Payment' : 'Submit Permit Application'}
            </IonButton>
        </div>
    );
};

export default Step5;
