import React, { useEffect, useState } from 'react';
import '../../Page.css';
/* Pullstate Store */
import { PermitStore } from '../../../store/PermitStore';
import {
    IonSpinner,
    IonRadioGroup,
    IonRadio,
    IonItem,
    IonLabel,
    IonButton,
    IonBadge,
    IonSelect,
    IonSelectOption,
} from '@ionic/react';
import dayjs from 'dayjs';

const Step2 = () => {
    const step = PermitStore.useState((s) => s.applicationStep);
    const application = PermitStore.useState((s) => s.application);
    const permitTypes = PermitStore.useState((s) => s.permitTypes);
    const [activitiesChoices, setActivitiesChoices] = useState(null);
    const [activities, setActivities] = useState([]);

    const handleChange = (e) => {
        const { name, paid, price, validFor, activities } = e.detail.value;

        if (activities.length > 1) {
            setActivitiesChoices(activities);
        }
        if (activities.length < 2) {
            setActivitiesChoices(null);
            PermitStore.update((s) => {
                s.application = {
                    ...s.application,
                    activities: activities[0],
                };
            });
        }

        if (validFor === 'EOY') {
            // console.log(dayjs(Date.now()).format('YYYY') + '-12-31');
            PermitStore.update((s) => {
                s.application = {
                    ...s.application,
                    type: name,
                    paid,
                    price,
                    validFor,
                    to: dayjs(Date.now()).format('YYYY') + '-12-31',
                    endDate: dayjs(Date.now()).format('YYYY') + '-12-31',
                };
            });
        } else {
            PermitStore.update((s) => {
                s.application = {
                    ...s.application,
                    type: name,
                    paid,
                    price,
                    validFor,
                    to: Date.now() + validFor,
                    endDate: Date.now() + validFor,
                };
            });
        }
    };

    useEffect(() => {
        PermitStore.update((s) => {
            s.application = {
                ...s.application,
                activities: activities,
            };
        });
    }, [activities]);

    // useEffect(() => {
    //     console.log(application.activities);
    // }, [application.activities]);

    if (permitTypes) {
        return (
            <div className={step === 2 ? 'steps' : 'ion-hide'}>
                <h2 className='ion-padding-horizontal onboardingTitles'>Choose Permit Type</h2>
                <div style={{ height: '40px' }} />
                <IonRadioGroup
                    mode='md'
                    aria-label={'List of burn permits'}
                    checked={application.type}
                    onIonChange={(e) => {
                        handleChange(e);
                    }}
                >
                    {permitTypes
                        ? permitTypes.map((type, i) => {
                              return (
                                  <div key={i}>
                                      <IonItem aria-label={'Burn permit type'} lines='none'>
                                          <IonLabel className='ion-text-wrap'>
                                              {type.name} {type.desc ? '-' : ''} {type.desc}
                                          </IonLabel>
                                          <IonRadio slot='start' value={type} />
                                          <IonBadge slot='end'>${type.price}</IonBadge>
                                      </IonItem>
                                  </div>
                              );
                          })
                        : null}
                </IonRadioGroup>
                {activitiesChoices ? (
                    <>
                        <h3 className='ion-padding'>
                            Please select all burning activities that apply:
                        </h3>
                        <IonSelect
                            multiple={true}
                            value={activities}
                            onIonChange={(e) => setActivities(e.detail.value)}
                            placeholder='Please select all that apply'
                            disabled={!activitiesChoices ? true : false}
                        >
                            {activitiesChoices &&
                                activitiesChoices.map((val, i) => (
                                    <IonSelectOption value={val} key={i}>
                                        {val}
                                    </IonSelectOption>
                                ))}
                        </IonSelect>
                    </>
                ) : null}
                <div style={{ height: 30 }} />
                <IonButton
                    disabled={!application.type || !application.activities}
                    className='ion-margin'
                    shape='round'
                    expand='block'
                    onClick={() => {
                        PermitStore.update((s) => {
                            s.applicationStep = 3;
                        });
                    }}
                >
                    Continue
                </IonButton>
            </div>
        );
    } else {
        return (
            <div>
                <p className='ion-text-center'>
                    <IonSpinner />
                </p>
            </div>
        );
    }
};

export default Step2;
