import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#fff',
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
});

const Terms = () => {
    return (
        <Page size='A4' style={styles.page}>
            <View style={styles.section}>
                <View style={{ marginVertical: 10 }}>
                    <Text
                        style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 18,
                            textAlign: 'center',
                            fontWeight: 'bold',
                            marginVertical: 5,
                        }}
                    >
                        A Permit is issued subject to the following conditions:
                    </Text>
                </View>
                <View style={{ marginLeft: 15 }}>
                    <Text
                        Text
                        style={{
                            fontFamily: 'Helvetica',
                            fontSize: 13,
                            textAlign: 'center',
                            marginVertical: 5,
                        }}
                    >
                        The applicant acknowledges having read and understood &nbsp;
                        <a
                            href='https://cochrane.civicweb.net/filepro/document/156000/1450-2021_Open%20Air%20Burning%20By-Law.pdf'
                            target='_blank'
                            rel='noreferrer'
                        >
                            By-law #1450-2021
                        </a>
                    </Text>
                    <Text
                        Text
                        style={{
                            fontFamily: 'Helvetica',
                            fontSize: 13,
                            textAlign: 'center',
                            marginVertical: 5,
                        }}
                    >
                        This Permit shall be in effect from the date of application to the last day
                        of December of the same calendar year.
                    </Text>
                    <Text
                        Text
                        style={{
                            fontFamily: 'Helvetica',
                            fontSize: 13,
                            textAlign: 'center',
                            marginVertical: 5,
                        }}
                    >
                        This Permit shall be kept at the site of the Open Air Burning and produce it
                        upon request from the Fire Department or authorized authorities.
                    </Text>
                    <Text
                        Text
                        style={{
                            fontFamily: 'Helvetica',
                            fontSize: 13,
                            textAlign: 'center',
                            marginVertical: 5,
                        }}
                    >
                        It is the responsibility of the person issued the permit to check and
                        monitor burning conditions as well as to ensure a “Total Burn Ban” in not in
                        place.
                    </Text>
                    <Text
                        Text
                        style={{
                            fontFamily: 'Helvetica',
                            fontSize: 13,
                            textAlign: 'center',
                            marginVertical: 5,
                        }}
                    >
                        The person issued the permit shall have at the location of the open air
                        burning, at all times, a competent person and enough water and equipment to
                        completely control and extinguish the fire.
                    </Text>
                    <Text
                        Text
                        style={{
                            fontFamily: 'Helvetica',
                            fontSize: 13,
                            textAlign: 'center',
                            marginVertical: 5,
                        }}
                    >
                        The person issued the permit shall extinguish the fire until dead out before
                        leaving the open air burn unattended.
                    </Text>
                    <Text
                        Text
                        style={{
                            fontFamily: 'Helvetica',
                            fontSize: 13,
                            textAlign: 'center',
                            marginVertical: 5,
                        }}
                    >
                        This Permit may be revoked at any time by the Fire Chief or designate for
                        failure to comply with the regulations of the by-law or unsafe practices.
                    </Text>
                    <Text
                        Text
                        style={{
                            fontFamily: 'Helvetica',
                            fontSize: 13,
                            textAlign: 'center',
                            marginVertical: 5,
                        }}
                    >
                        This Permit is granted for the purpose of open air burning, the size of
                        a“Campfire” is to be limited to one 1-meter x 1-meter x 1-meter (3.3’ x 3.3’
                        x 3.3’) on a non-combustible surface. The fire shall be solely for the
                        purposes of cooking food, providing warmth or recreational enjoyment.
                    </Text>
                    <Text
                        Text
                        style={{
                            fontFamily: 'Helvetica',
                            fontSize: 13,
                            textAlign: 'center',
                            marginVertical: 5,
                        }}
                    >
                        This Permit is granted for the purpose of open air burning, the size of a
                        “Brush Pile” is to be limited to one 1.8-meters x 1.8-meters x 1.8-meters
                        (6’ x 6’ x 6’) and solely for the purposes of burning piled wood, brush and
                        leaves.
                    </Text>
                    <Text
                        Text
                        style={{
                            fontFamily: 'Helvetica',
                            fontSize: 13,
                            textAlign: 'center',
                            marginVertical: 5,
                        }}
                    >
                        This Permit is granted for the purpose of open air burning, the size of a
                        “Incinerator Fire” is to be limited to one container designed for purposes
                        and constructed completely of non-combustible material placed on
                        non-combustible surface. A maximum of 202 liters (55 gallon) and an outlet
                        or exhaust opening covered by a screen having a mesh of not more than 7mm
                        (1/4”).
                    </Text>
                    <Text
                        Text
                        style={{
                            fontFamily: 'Helvetica',
                            fontSize: 13,
                            textAlign: 'center',
                            marginVertical: 5,
                        }}
                    >
                        This Permit is granted for the purpose of open air burning, the size of
                        grass, hay, straw or standing material shall not exceed 15 meters (49.5‘) to
                        the leading edge where sufficient persons and resources are available to
                        contain the leading edge of the fire to within 15 meters (49.5’).
                    </Text>
                    <Text
                        Text
                        style={{
                            fontFamily: 'Helvetica',
                            fontSize: 13,
                            textAlign: 'center',
                            marginVertical: 5,
                        }}
                    >
                        From April 1st until October 31st burning is permitted after 5 PM until 8 AM
                        only. No Daytime Burning is allowed.
                    </Text>
                </View>
                <View style={{ marginVertical: 10 }}>
                    <Text
                        style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 18,
                            textAlign: 'center',
                            fontWeight: 'bold',
                            marginVertical: 5,
                        }}
                    >
                        <a
                            href='https://cochrane.civicweb.net/filepro/document/156000/1450-2021_Open%20Air%20Burning%20By-Law.pdf'
                            target='_blank'
                            rel='noreferrer'
                        >
                            The Corporation of the Town of Cochrane Schedule “A” to By-Law
                            #1450-2021
                        </a>
                    </Text>
                </View>
                <View style={{ marginVertical: 10 }}>
                    <Text
                        style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 18,
                            textAlign: 'center',
                            fontWeight: 'bold',
                            marginVertical: 5,
                            color: '#ff0000',
                        }}
                    >
                        Permit holders are permitted to have their camp fires prior to their fire
                        pit inspection being completed
                    </Text>
                </View>
            </View>
        </Page>
    );
};

export default Terms;
